:local(.normal) {
  background: #021647;
  font-size: 15px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 23px;
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  height: 46px;
  line-height: 46px;
  font-family: 'Graphik Semibold';
  cursor: pointer;
}

:local(.normal:hover) {
  background: #102A6A;
}

:local(.normal:active) {
  background: #1D3F93;
}

:local(.disabled) {
  background: #D3DCE6;
  font-size: 15px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 23px;
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  height: 46px;
  line-height: 46px;
  font-family: 'Graphik Semibold';
  cursor: pointer;
}

:local(.loading) {
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  height: 46px;
}

:local(.outlined) {
  border: solid 2px #021647;
  font-size: 15px;
  color: #021647;
  text-align: center;
  border-radius: 23px;
  display: inline-block;
  line-height: 42px;
  padding-left: 32px;
  padding-right: 32px;
  height: 42px;
  font-family: 'Graphik Semibold';
  cursor: pointer;
}

:local(.inversed) {
  background: #FFFFFF;
  font-size: 15px;
  color: #021647;
  text-align: center;
  border-radius: 23px;
  display: inline-block;
  line-height: 46px;
  padding-left: 32px;
  padding-right: 32px;
  height: 46px;
  font-family: 'Graphik Semibold';
  cursor: pointer;
}
