
:local(.red) {
  background-color: #ffeff2;
  border-left: 1px solid #ffdfe5;
}

:local(.blue) {
  background-color: #ecf3f9;
  border-left: 1px solid #d5e5f0;
}

:local(.mono) {
  background-color: #DBDBDB;
  border-left: 1px solid #C6C6C6;
}

:local(.normal) {
  background-color: #F4F4F4;
  border-left: 1px solid #ECECEC;
}

:local(.green) {
  background-color: #ecf8f5;
  border-left: 1px solid #cae9e0
}
