:local(.block) {
  width: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;
  align-items: center;
  height: 419px;
  background: #021647;
  position: relative;
}

:local(.blockInside) {
  max-width: 1150px;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}

:local(.title) {
  font-size: 25px;
  font-family: 'Graphik Semibold';
  color: #fff;

}

:local(.subtitle) {
  font-size: 20px;
  font-family: 'Graphik Regular';
  color: #fff;
  line-height: 35px;
  margin-top: 10px;
  max-width: 340px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
}
