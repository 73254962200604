:local(.container) {
  display: inline-block;
  height: 46px;
}

:local(.flex) {
  display: flex;
  width: 100%;
}

:local(.circle) {
  width: 46px;
  height: 46px;
  background: #ffffff;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.percentage) {
  font-family: 'Graphik Semibold';
  font-size: 18px;
  color: #021647;
  margin-left: 15px;
  margin-right: 30px;
  align-self: center;
}

:local(.percentageSign) {
  font-size: 14px;
}
