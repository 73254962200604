:local(.block) {
  width: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

:local(.blockInside) {
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

:local(.generate) {
  margin-right: 10px;
  display: inline-block;
}

:local(.input) {
  height:70px;
  font-size: 20px;
  border: 0;
  outline: 0;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid black;
  margin-bottom: 31px;
}

:local(.textContent) {
  max-width: 453px;
  align-self: center;
}

:local(.blockTitle) {
  font-size: 40px;
  font-family: 'Graphik Semibold';
  letter-spacing: 1.5px;
  line-height: 50px;
  color: #021647;
}

:local(.blockSubtitle){
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 40px;
  margin-top: 18px;
  margin-bottom: 10px;
  font-family: 'Graphik Medium';
  color: #021647;
}

:local(.logo){
  position: absolute;
  top: 59px;
}

:local(.scrollDownNotice){
  position: absolute;
  bottom: 41px;
  color: #021647;
  text-align: center;
  font-family: 'Graphik Semibold';
  font-size: 20px;
}

:local(.scrollDownText){
  margin-bottom: 10px;
}
