:local(.container) {
  display: inline-block;
  height: 46px;
}

:local(.container span) {
  padding-left: 24px;
  padding-right: 24px;
}

:local(.flex) {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

:local(.download) {
  font-family: 'Graphik Semibold';
  font-size: 13px;
}

:local(.logo) {
  align-self: center;
  margin-left: 10px;
  height: 40px;
}
