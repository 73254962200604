:local(.roundedButton) {
  width: 45px;
  height: 45px;
  background: #021647;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}

:local(.roundedButton):hover {
  background: #102A6A;
}

:local(.roundedButton):active {
  background: #1D3F93;
}
