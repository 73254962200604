:local(.container) {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}

:local(.inside) {
  max-width: 461px;
}

:local(.title) {
  font-size: 40px;
  font-family: 'Graphik Semibold';
  letter-spacing: 1.5px;
  line-height: 50px;
  color: #021647;
  margin-bottom: 30px;
}

:local(.select) {
  height:70px;
  font-size: 20px;
  border: 0;
  appearance: none;
  border-radius: 0px;
  outline: 0;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid black;
  margin-bottom: 40px;
}

:local(.logo) {
  position: absolute;
  top: 66px;
  left:50%;
  margin-left: -35px;
}
