
:local(.white) {
  background: #fff;
}

:local(.green) {
  background: #ECF8F5;
  border-top: 1px #CDEAE1 solid;
  border-bottom: 1px #CDEAE1 solid;
}

:local(.red) {
  background: #FFEFF2;
  border-top: 1px #EAD5D9 solid;
  border-bottom: 1px #EAD5D9 solid;
}


:local(.blue) {
  background: #E6EDF3;
  border-top: 1px #D5E5F0 solid;
  border-bottom: 1px #D5E5F0 solid;
}
