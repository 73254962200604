:local(.container) {
  height: 100vh;
  width: 100%;
  display: flex;
}

:local(.left) {
  height: 100vh;
  flex: 1; 
}

:local(.sidebar) {
  height: 100vh;
  width: 500px;
}
