@font-face {
      font-family: 'Graphik Regular';
      src: url('fonts/Graphik-Regular-Web.eot');
      src: url('fonts/Graphik-Regular-Web.eot#iefix') format('embedded-opentype'),
      url('fonts/Graphik-Regular-Web.woff2') format('woff2'),
      url('fonts/Graphik-Regular-Web.woff') format('woff'),
      url('fonts/Graphik-Regular-Web.ttf') format('truetype');

      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: 'Graphik Medium';
      src: url('fonts/Graphik-Medium-Web.eot');
      src: url('fonts/Graphik-Medium-Web.eot#iefix') format('embedded-opentype'),
      url('fonts/Graphik-Medium-Web.woff2') format('woff2'),
      url('fonts/Graphik-Medium-Web.woff') format('woff'),
      url('fonts/Graphik-Medium-Web.ttf') format('truetype');

      font-weight: normal;
      font-style: normal;
}

@font-face {
      font-family: 'Graphik Semibold';
      src: url('fonts/Graphik-Semibold-Web.eot');
      src: url('fonts/Graphik-Semibold-Web.eot#iefix') format('embedded-opentype'),
      url('fonts/Graphik-Semibold-Web.woff2') format('woff2'),
      url('fonts/Graphik-Semibold-Web.woff') format('woff'),
      url('fonts/Graphik-Semibold-Web.ttf') format('truetype');

      font-weight: normal;
      font-style: normal;
}

/* The player needs height: 100% */
html, body, #root, div[role="group"] {
  height: 100%;
  font-family: 'Graphik Regular';
}

/* Hide player sidebar and navigation */
.Sidebar__sidebar___3jZX3, .SidebarNavigation__wrapper___2LSXh  {
  display: none;
}
