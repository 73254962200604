:local(.block) {
  width: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;
  align-items: center;
  min-height: 577px;
}

:local(.white) {
  backgorund: #000;
}

:local(.blockInside) {
  max-width: 1050px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

:local(.textContent) {
  max-width: 492px;
  align-self: center;
}

:local(.blockTitle) {
  font-size: 40px;
  font-family: 'Graphik Semibold';
  line-height: 48px;
  color: #021647;
}

:local(.blockTitle span) {
  font-family: 'Graphik Regular';
}

:local(.blockSubtitle){
  font-size: 20px;
  color: #021647;
  line-height: 34px;
  margin-top: 20px;
  font-family: 'Graphik Regular';
  font-size: 20px;
}

:local(.reverse){
  flex-direction: row-reverse;
}
