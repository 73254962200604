
:local(.sidebar) {
  background-color: #CAE9E0;
  color: #021647;
  width: 750px;
  max-width: 100%;
  padding: 60px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 25px;
  text-align: left;
  height: 100vh;
  position: relative;
}

:local(.tabs) {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


:local(.switchyButton) {
  padding: 0 20px;
  cursor: pointer;
  border-radius: 22px;
  line-height: 44px;
  display: inline-block;
  font-family: 'Graphik Semibold';
  font-size: 13px;
}

:local(.normal):hover, :local(.normal-active) {
  background: #021647;
  color: white;
}

:local(.red):hover, :local(.red-active) {
  background: #FE365C;
  color: white;
}

:local(.green):hover, :local(.green-active) {
  background: #1BAA7D;
  color: white;
}

:local(.blue):hover, :local(.blue-active) {
  background: #0C67AC;
  color: white;
}

:local(.mono):hover, :local(.mono-active) {
  background: #000;
  color: white;
}

:local(.button) {
  background-color: #1BAA7D;
  border-radius: 22.5px;
  width: 83px;
  height: 45px;
}

:local(.header) {
  margin-top: 54px;
  font-size: 30px;
  line-height: 40px;
  font-family: 'Graphik Semibold';
  color: #021647;
}

:local(.paragraph) {
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 18px;
  font-family: 'Graphik Regular';
  color: #021647;
}

:local(.severity) {
  font-family: 'Graphik Medium';
  font-size: 15px;
  color: #021647;
  margin-bottom: 10px;
}

:local(.spacer) {
  margin-bottom: 1rem;
}

:local(.radioInput) {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

:local(.left) {
  align-self: center;
  margin-right: 20px;
}
:local(.right) {
  flex: 1;
}

:local(.radioLabel) {
  color: #021647;
  font-size: 15px;
  font-family: 'Graphik Medium';
  text-transform: uppercase;
}

:local(.radioSublabel) {
  color: #8392A7;
  font-size: 11px;
  font-family: 'Graphik Medium';
}

:local(.radioButtons) {
  margin-bottom: 2rem;
}

:local(.colorsUsing) {
  margin-bottom: 40px;
}

:local(.colorsUsing h3) {
  font-family: 'Graphik Medium';
  font-size: 15px;
  color: #021647;
  margin-bottom: 10px;
}


:local(.radio) {
  color: #021647;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 21px;
  width: 389px;
  text-align: left;
}

:local(.gender) {
  width: 46px;
  height: 46px;
  background: #ffffff;
}

:local(.downloadContainer) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  height: 100px;
}

:local(.downloadLeft) {
  font-family: 'Graphik Medium';
  font-size: 14px;
  color: #021647;
  margin-right: 5px;
  margin-left: 60px;
}

:local(.downloadRight) {
  align-self: center;
  margin-right: 60px;
}
